import Image from 'next/image';
import { MessageStyle } from '../../../styles/components/common/ui';

export interface IMessageProps {
  children: React.ReactNode;
  iconPath?: string;
  type: 'success' | 'danger' | 'neutral' | 'subtle';
  darkMode?: boolean;
}

const Message = (props: IMessageProps) => {
  return (
    <div className={`${MessageStyle['message']} ${MessageStyle[props.type]} ${props.darkMode ? MessageStyle['message-dark-mode'] : ''}`}>
      {props.iconPath && <Image className={MessageStyle['message-icon']} src={props.iconPath} width={42} height={42} alt={props.type} />}
      <div className={MessageStyle['message-content']}>{props.children}</div>
    </div>
  );
};

export default Message;
